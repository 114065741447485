import { FC } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { useI18nContext } from 'context/language';
import { Portlet } from 'types/config';

import ts from './i18n';
import { styles } from './portlet-card.styles';

export type Props = Portlet;

export const PortletCard: FC<Props> = ({ image, title, description, link }) => {
    const { i18nString } = useI18nContext();
    const { card, content } = styles;

    const visitPortlet = (link: string): void => {
        document.location.pathname = link;
    };

    return (
        <Card sx={card}>
            <CardMedia alt={title} component='img' height='140' image={process.env.PUBLIC_URL + image} />
            <CardContent sx={content}>
                <Typography gutterBottom component='div' variant='h5'>
                    {title}
                </Typography>
                <Typography color='text.secondary' variant='body2'>
                    {description}
                </Typography>
            </CardContent>
            <CardActions>
                <Button onClick={visitPortlet.bind(null, link)}>{i18nString(ts.VISIT)}</Button>
            </CardActions>
        </Card>
    );
};
