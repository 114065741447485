/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';

import ReactDOM from 'react-dom';

import App from './app';

declare var __webpack_nonce__: string;

__webpack_nonce__ = window.__CSP_NONCE__ as string;

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
