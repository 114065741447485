import translations from 'translations.json';

import { Languages } from 'types/i18n/translations';

const defaultLanguage: Languages = Languages.EN;

const supportedLanguages = [
    {
        name: 'English (US)',
        tag: Languages.EN,
    },
    {
        name: 'Japanese',
        tag: Languages.JA,
    },
    {
        name: 'Chinese',
        tag: Languages.ZH,
    },
];

export { supportedLanguages, defaultLanguage, translations };
