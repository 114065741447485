const translations = {
    M_CRM_TITLE: {
        description: 'mini CRM Portlet title',
        primaryValue: 'mCRM',
        stringKey: 'portlets_m_srm_title',
    },
    M_CRM_DESCRIPTION: {
        description: 'mini CRM Portlet description',
        primaryValue: 'mini CRM',
        stringKey: 'portlets_m_srm_description',
    },
    POSTPURCHASE_TITLE: {
        description: 'Postpurchase Portlet title',
        primaryValue: 'Postpurchase',
        stringKey: 'portlets_postpurchase_title',
    },
    POSTPURCHASE_DESCRIPTION: {
        description: 'Postpurchase Portlet description',
        primaryValue: 'Postpurchase',
        stringKey: 'portlets_postpurchase_description',
    },
    CHECKOUT_TITLE: {
        description: 'Checkout Portlet title',
        primaryValue: 'Checkout',
        stringKey: 'portlets_checkout_title',
    },
    CHECKOUT_DESCRIPTION: {
        description: 'Checkout Portlet description',
        primaryValue: 'Checkout on behalf of consumer',
        stringKey: 'portlets_checkout_description',
    },

    GIFT_CARD_TITLE: {
        description: 'Gift Card Portlet title',
        primaryValue: 'Gift Cards',
        stringKey: 'portlets_gift_card_title',
    },
    GIFT_CARD_DESCRIPTION: {
        description: 'Gift Card Portlet description',
        primaryValue: 'Check details of consumer gift card',
        stringKey: 'portlets_gift_card_description',
    },

    MEMBER_INFO_TITLE: {
        description: 'Member Info Portlet title',
        primaryValue: 'Member Info',
        stringKey: 'portlets_bember_info_title',
    },
    PROMOCODE_INFO_TITLE: {
        description: 'Promocode Info title',
        primaryValue: 'Promocode',
        stringKey: 'portlets_promocode_info_title',
    },
    PROMOCODE_INFO_DESCRIPTION: {
        description: 'Promocode description',
        primaryValue: 'Operations with promocodes',
        stringKey: 'portlets_promocode_info_description',
    },

    MEMBER_INFO_DESCRIPTION: {
        description: 'Gift Card Portlet description',
        primaryValue: 'Check customers details, orders, payment details etc',
        stringKey: 'portlets_member_info_description',
    },
};

module.exports = translations;
