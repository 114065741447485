import { OKTAConfig } from './types/config';

const AUTH_CALLBACK_URL = '/implicit/callback/';

/**
 * Application configuration
 */
export const Config: any = {
    BASENAME: `${process.env.PUBLIC_URL?.toLowerCase()}`,

    AUTH_CALLBACK_URL,

    REACT_APP_PORTLET: process.env.REACT_APP_PORTLET,

    Okta: {
        issuer: window.__CONFIG__?.okta.issuer,
        clientId: window.__CONFIG__?.okta.clientId,
        redirectUri: window.location.origin + AUTH_CALLBACK_URL,
        tokenManager: {
            /**
             * each app should have it's own storage key based on the client id of the app
             */
            storageKey: `${window.__CONFIG__?.okta.clientId}.${process.env.REACT_APP_PORTLET?.toLowerCase()}`,
        },
    } as OKTAConfig,

    portlets: window.__CONFIG__.portlets,
};
