import { styled, Theme } from '@mui/material';

export const Image = styled('img')(
    ({ theme }: { theme: Theme }) => `
        max-width: ${theme.spacing(14)};
        margin-right: ${theme.spacing(3)};
        padding-bottom: ${theme.spacing(1)}
    `
);

export const styles = {
    appBar: { minHeight: '64px', display: 'flex' },
    header: { flexGrow: 1 },
    athlete: { mr: 2 },
};
