import { FC, useState, MouseEvent } from 'react';

import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { Button, Menu, MenuItem } from '@mui/material';

import { useLanguageContext } from 'context/language';
import { supportedLanguages } from 'context/language/i18n';
import { Languages } from 'types/i18n/translations';

export const LanguagePicker: FC = () => {
    const [language, setLanguage] = useLanguageContext();
    const selectedLanguage = supportedLanguages.find((lang) => lang.tag === language);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChanges = (language: Languages) => {
        setLanguage(language);
        handleClose();
    };
    return (
        <>
            <Button disableElevation aria-haspopup='true' startIcon={<LanguageOutlinedIcon />} variant='contained' onClick={handleClick}>
                {selectedLanguage?.name}
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                aria-labelledby='demo-positioned-button'
                id='demo-positioned-menu'
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handleClose}
            >
                {supportedLanguages.map(({ name, tag }) => (
                    <MenuItem key={tag} onClick={handleChanges.bind(null, tag)}>
                        {name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
