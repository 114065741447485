import React, { FC } from 'react';

import { LoginCallback, SecureRoute } from '@okta/okta-react';

import { Config } from 'config';
import { Route, Switch } from 'react-router-dom';

import { Header } from 'components/header/header';
import { Portlets } from 'components/portlets/portlets';

export const Home: FC = () => {
    return (
        <Switch>
            <Route component={LoginCallback} path={Config.AUTH_CALLBACK_URL} />
            <SecureRoute exact path='/'>
                <Header />
                <Portlets />
            </SecureRoute>
        </Switch>
    );
};
