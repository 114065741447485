import { FC } from 'react';

import { AppBar, Toolbar, Typography } from '@mui/material';

import logo from 'assets/omobo.png';
import { useAthleteContext } from 'context/athlete';
import { useI18nContext } from 'context/language';

import { LanguagePicker } from '../language-picker';
import { Image, styles } from './header.styles';
import ts from './i18n';

export const Header: FC = () => {
    const { appBar, header, athlete } = styles;
    const { i18nString } = useI18nContext();

    const userInfo = useAthleteContext();

    return (
        <AppBar position='sticky' sx={appBar}>
            <Toolbar>
                <Image alt={'OMOBO'} loading='lazy' src={logo} />
                <Typography sx={header} variant='h5'>
                    {i18nString(ts.PORTLETS)}
                </Typography>
                {userInfo && (
                    <Typography sx={athlete} variant='body1'>
                        {`${i18nString(ts.ATHLETE)}: ${userInfo?.name}`}
                    </Typography>
                )}
                <LanguagePicker />
            </Toolbar>
        </AppBar>
    );
};
