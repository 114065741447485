const translations = {
    PORTLETS: {
        description: 'Portlets app title',
        primaryValue: 'Portlets',
        stringKey: 'header_portlets',
    },
    ATHLETE: {
        description: 'Athlete title',
        primaryValue: 'Athlete',
        stringKey: 'header_athlete',
    },
};

module.exports = translations;
