import { useCallback, useRef, FC } from 'react';

import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

import { BrowserRouter } from 'react-router-dom';

import { Config } from './config';
import { Home } from './containers/home';
import { Athlete } from './context/athlete';
import { Language } from './context/language';

export const App: FC = () => {
    const oktaAuth = useRef(new OktaAuth(Config.Okta));

    const restoreOriginalUri = useCallback((oktaAuth: OktaAuth, originalUri: string) => {
        window.location.replace(originalUri);
    }, []);

    const theme = createTheme({
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        backgroundColor: '#f5f5f5',
                    },
                },
            },
        },
        palette: {
            primary: {
                main: '#3f51b5',
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline>
                <BrowserRouter basename={Config.BASENAME}>
                    <Language>
                        <Security oktaAuth={oktaAuth.current} restoreOriginalUri={restoreOriginalUri}>
                            <Athlete>
                                <Home />
                            </Athlete>
                        </Security>
                    </Language>
                </BrowserRouter>
            </CssBaseline>
        </ThemeProvider>
    );
};

export default App;
