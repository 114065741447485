import { FC } from 'react';

import { Box } from '@mui/material';

import { Config } from 'config';

import { Portlet } from 'types/config';

import { useI18nContext } from '../../context/language';
import { PortletCard } from '../portlet-card';
import ts from './i18n';
import { styles } from './portlets.styles';

export const Portlets: FC = () => {
    const { container } = styles;
    const { i18nString } = useI18nContext();

    return (
        <Box sx={container}>
            {Config.portlets.map((portlet: Portlet) => {
                const { title, description, ...props } = portlet;

                return (
                    <PortletCard
                        description={i18nString((ts as any)[description])}
                        key={portlet.title}
                        title={i18nString((ts as any)[title])}
                        {...props}
                    />
                );
            })}
        </Box>
    );
};
