import { Languages } from 'types/i18n/translations';

import { defaultLanguage } from './i18n';

const LOCALSTORAGE_LANGUAGE_KEY = `csp-portlet-${process.env.REACT_APP_PORTLET}-locale`;
const QUERY_PARAM_LANGUAGE = 'locale';

const resolveFromURL = (): Languages => new URLSearchParams(window.location.search).get(QUERY_PARAM_LANGUAGE) as Languages;

const resolveFromStorage = (): Languages => window.localStorage.getItem(LOCALSTORAGE_LANGUAGE_KEY) as Languages;

const storeLanguage = (language: Languages): void => {
    language && window.localStorage.setItem(LOCALSTORAGE_LANGUAGE_KEY, language);
};

const resolveLanguage = (): Languages => {
    return resolveFromURL() || resolveFromStorage() || defaultLanguage;
};

export { resolveLanguage, storeLanguage };
